<template>
  <div v-if="changelog_index !== null" :class="{
    'columns': columns !== undefined,
    'is-centered': columns !== undefined,
  }">
    <div :class="{
      'column': columns !== undefined,
      'is-4-desktop': columns !== undefined,
      'is-7-tablet': columns !== undefined,
      'is-12-mobile': columns !== undefined,
    }">
      <article class="message is-info" style="pointer-events: all;">
        <div class="message-header">
          <p style="font-weight: normal;font-family: cabin_sketch_regular;color: white;">{{ $t('_changelog.' + changelog_index + '.title') }}</p>
          <button class="delete" aria-label="delete" @click="close"></button>
        </div>
        <div
          class="message-body"
          style="padding-top: 6px;padding-bottom: 9px;white-space: pre-wrap;line-height: 14px;font-size: 14px;font-family: cabin_sketch_regular;"
        >{{ $t('_changelog.' + changelog_index + '.text').replaceAll(/\\n/g, '\n') }}</div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationChangelog',

  data() {
    return {
      changelog_index: null,
    };
  },

  created() {
    // Vérifier qu'il y ait bien des seen_words (càd que ça soit un joueur qui a déjà joué avant)
    let seenWords = this.$session.data._seen_words !== undefined && Array.isArray(this.$session.data._seen_words) && this.$session.data._seen_words.length > 0;
    let curVersion = this.$session.data._last_version;
    //console.log('seenWords =', seenWords);
    //console.log('curVersion =', curVersion);
    if (
      (seenWords && (curVersion === undefined || curVersion === null || curVersion <= 0)) ||
      (curVersion !== undefined && curVersion !== null && this.$sketchy_current_version > curVersion)
    ) {
      this.changelog_index = this.$sketchy_current_version;
    } else {
      //console.log('skip affichage bandeau changelog');
      this.$session._last_version = this.$sketchy_current_version;
    }
  },

  methods: {
    close() {
      this.changelog_index = null;
      this.$session._last_version = this.$sketchy_current_version;
    },
  },

  props: ['columns'],
}
</script>
