var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.changelog_index !== null)?_c('div',{class:{
  'columns': _vm.columns !== undefined,
  'is-centered': _vm.columns !== undefined,
}},[_c('div',{class:{
    'column': _vm.columns !== undefined,
    'is-4-desktop': _vm.columns !== undefined,
    'is-7-tablet': _vm.columns !== undefined,
    'is-12-mobile': _vm.columns !== undefined,
  }},[_c('article',{staticClass:"message is-info",staticStyle:{"pointer-events":"all"}},[_c('div',{staticClass:"message-header"},[_c('p',{staticStyle:{"font-weight":"normal","font-family":"cabin_sketch_regular","color":"white"}},[_vm._v(_vm._s(_vm.$t('_changelog.' + _vm.changelog_index + '.title')))]),_c('button',{staticClass:"delete",attrs:{"aria-label":"delete"},on:{"click":_vm.close}})]),_c('div',{staticClass:"message-body",staticStyle:{"padding-top":"6px","padding-bottom":"9px","white-space":"pre-wrap","line-height":"14px","font-size":"14px","font-family":"cabin_sketch_regular"}},[_vm._v(_vm._s(_vm.$t('_changelog.' + _vm.changelog_index + '.text').replaceAll(/\\n/g, '\n')))])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }